<template>
  <div>
    <header>
      <h3 class="has-text-weight-bold has-text-dark mb-2">Cost Input</h3>
      <p class="has-text-secondary mb-3">Enable Editable Cost</p>

      <b-field>
        <b-switch v-model="cost"/>
      </b-field>
    </header>

    <div class="is-divider my-5"></div>

  </div>
</template>

<script>
export default {
  name: "CostSetting",
  computed: {
    cost: {
      get() {
        return this.$store.state.setting.cost
      },
      set() {
        this.$store.dispatch('setting/toggleCost')
      }
    }
  },
  created() {
    this.$loading(false)
  },
}
</script>
